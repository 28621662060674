<template>
  <div class="satisfactory-section">
    <div class="row">
      <H2 class="section-title">Satisfaction form</H2>
    </div>
    <form class="satisfactory-form">
      <div class="row">
        <div class="col-md-6">
          <div class=" saticfaction-radio" v-for="(radio, index) in formRadios" :key="index">
            <div class="form-check form-check-inline col-md-3">{{ radio.title }}</div>
            <div class="form-check form-check-inline col-md-1" v-for="(id, index) in 5" :key="index">
              <input class="form-check-input" type="radio" :name="radio.name" :id="radio.name + id" :value="id + 1">
              <label class="form-check-label" :for="radio.name + id">{{ id }}</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Comment</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
        </div>
      </div>
      <div class="row"><button type="submit" class="btn btn-primary saticfaction-submit col-2">Submit</button></div>

    </form>
  </div>
</template>

<script>

export default {
  name: 'SatisfactionForm',
  data() {
    return {
      formRadios: [
        {
          'name': 'quality',
          'title': 'Quality'
        },
        {
          'name': 'user-interface',
          'title': 'User interface'
        },
        {
          'name': 'price',
          'title': 'Price'
        }]
    }
  }
}

</script>

<style scooped>
.section-title {
  text-align: center;
  margin: 10px;
}

.satisfactory-section {
  padding: 50px;
}

.saticfaction-radio {
  margin-top: 30px;
}

.saticfaction-submit {
  margin: auto;
  margin-top: 30px !important;
}
textarea {
   resize: none;
   height: 170px;
}
</style>
