<template>

  <div>
    {{cardData.data.id}}
  </div>

</template>

<script>

  export default {
    name: 'CardData',
    props: ['cardData']
  }

</script>

