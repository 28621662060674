<template>

  <svg width="285px" height="34px" viewBox="0 0 285 34" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>CARIAD</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="CARIAD.LOGO.RGB.L.NEGATIVE" fill="#FFFFFE">
        <path
          d="M0.819,17.003 C0.819,7.816 8.113,0.299 17.078,0.299 C22.423,0.299 27.156,2.915 30.108,6.981 L28.716,8.205 C26.099,4.475 21.867,2.08 17.078,2.08 C9.115,2.08 2.656,8.763 2.656,17.003 C2.656,25.244 9.115,31.926 17.078,31.926 C21.867,31.926 26.099,29.531 28.716,25.801 L30.108,27.027 C27.156,31.091 22.423,33.708 17.078,33.708 C8.113,33.708 0.819,26.246 0.819,17.003"
          id="Fill-4"></path>
        <polygon id="Fill-5" points="166.626 32.845 168.463 32.845 168.463 1.161 166.626 1.161"></polygon>
        <polygon id="Fill-29" points="206.811 24.045 219.49 24.045 219.49 22.207 206.811 22.207"></polygon>
        <polyline id="Fill-30"
          points="214.115 1.023 214.113 1.029 212.116 1.029 212.115 1.025 212.113 1.029 198.726 32.26 200.415 32.985 213.117 3.351 225.887 32.985 227.575 32.257 214.117 1.029 214.115 1.023">
        </polyline>
        <polygon id="Fill-31" points="64.82 24.045 77.5 24.045 77.5 22.207 64.82 22.207"></polygon>
        <polyline id="Fill-32"
          points="72.125 1.023 72.123 1.029 70.126 1.029 70.125 1.025 70.123 1.029 56.736 32.26 58.425 32.985 71.127 3.351 83.897 32.985 85.585 32.257 72.127 1.029 72.125 1.023">
        </polyline>
        <polygon id="Fill-33" points="258.16 32.843 259.997 32.843 259.997 1.166 258.16 1.166"></polygon>
        <path
          d="M268.385,1.109 L262.399,1.109 L262.399,3.057 L268.385,3.057 C276.075,3.057 282.331,9.314 282.331,17.003 C282.331,24.694 276.075,30.95 268.385,30.95 L262.399,30.95 L262.399,32.898 L268.385,32.898 C277.149,32.898 284.279,25.768 284.279,17.003 C284.279,8.239 277.149,1.109 268.385,1.109"
          id="Fill-34"></path>
        <polyline id="Fill-35" points="126.449 19.823 134.238 33.012 135.802 32.063 128.013 18.873 126.449 19.823">
        </polyline>
        <path
          d="M125.244,15.415 L117.703,15.415 L117.703,2.678 L124.742,2.678 C125.915,2.678 127.032,2.735 128.094,2.957 C129.155,3.125 130.105,3.46 130.942,3.963 C131.725,4.41 132.339,5.08 132.842,5.918 C133.289,6.7 133.568,7.762 133.568,8.991 C133.568,11.058 132.898,12.567 131.501,13.683 C130.048,14.801 127.982,15.415 125.244,15.415 Z M132.283,15.304 C133.177,14.689 133.96,13.851 134.518,12.845 C135.132,11.84 135.411,10.499 135.411,8.991 C135.411,7.371 135.076,5.975 134.462,4.913 C133.847,3.907 133.066,3.07 132.06,2.455 C131.11,1.84 130.048,1.449 128.819,1.226 C127.647,1.002 126.418,0.947 125.3,0.947 L115.859,0.947 L115.859,32.677 L117.703,32.677 L117.703,17.147 L124.965,17.147 L125.97,17.091 C127.032,17.091 128.15,16.923 129.211,16.7 C130.328,16.42 131.334,15.918 132.283,15.304 L132.283,15.304 Z"
          id="Fill-36"></path>
      </g>
    </g>
  </svg>


</template>

<script>

export default {
  name: 'CariadLogo'
}

</script>


