<template>

  <nav id="navbar-kuma" class="navbar navbar-expand-lg navbar-light px-3 sticky-top">
    <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" class="img-fluid logo" /></a>
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="#why-kuma" id="menu-1">Why?</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#how-to-play" id="menu-2">How?</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#who-is" id="menu-3">Who?</a>
        </li>
      </ul>
    </div>
  </nav>

</template>

<script>

export default {
  name: 'MainMenu'
}

</script>

<style scoped>
.logo {
  max-width: 70px;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.navbar-brand {
  font-size: 1.6em;
}

.nav-link {
  text-decoration: none;
  color: black;
  font-size: 2.4em;
  padding-bottom: 0;
}

.active {
  border-bottom-style: solid;
}

#navbar-kuma {
  border-radius: 25px;
  border: 2px solid;
  background: white;
}
</style>

