import { createRouter, createWebHistory } from 'vue-router'
import CardDisplay from '@/modules/card/pages/CardDisplay'
import CariadLanding from '@/modules/formPage/pages/CariadLanding'
import KumaLanding from '@/modules/landingPage/pages/KumaLanding'

const routes = [
  { path: '/cards/:cardId', component: CardDisplay },
  { path: '/', component: KumaLanding },
  { path: '/cariad2022', component: CariadLanding }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
