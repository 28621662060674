<template>

  <div id="how-to-play">
    <h1><span id="menu-2">How to play Kuma karuta</span></h1>
    <div class="row">
      <div class="col-md-6">
        Play for yourself:<ol>
          <li>Once a day, be curious</li>
          <li>Shuffle the cards</li>
          <li>Pick one card</li>
          <li>Perform the activity</li>
          <li>Next day, same game</li>
        </ol>
        You can choose the timing to perform 
the daily activity to whenever it fits
within the next 24 hours, but we recommend
to do it right away
      </div>
      <div class="col-md-6"><img src="/assets/images/kuma_deck.jpg" class="img-fluid content-image" /></div>
    </div>
    <div class="row">
      <div class="col-md-6"><img src="/assets/images/card_bench.jpg" class="img-fluid content-image" /></div>

      <div class="col-md-6">
        Options to A:<ul>
          <li>You can do it on weekly base & select
            7 cards to plan into your weekly schedule</li>
        </ul>

        Options to B:<ul>
          <li>Play with family, friends or colleagues:
            Every day or on weekly base, one of or everyone selects a card, shares with others & perform the activity
          </li>
        </ul>

        Further options:<ul>
          <li>Coming soon...
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SectionTwo"
}

</script>

<style scoped>
#menu-2::after {
  content: url('@/assets/images/menu/how_static.png');
}

#menu-2:hover::after {
  content: url('@/assets/images/menu/how_ani.gif');
}
</style>

