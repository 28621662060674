<template>

  <div class="row introduction">
    <div class="col-6"></div>
    <div class="col-6 description"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur auctor nec quam
      nec gravida. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce
      scelerisque imperdiet libero, at sollicitudin odio commodo at. Nunc pretium porta elementum. Phasellus ut orci ut
      quam porta vulputate.</p><p>Integer quis consectetur purus. Aenean porta urna quis tellus gravida, nec consequat tortor
      pharetra. Curabitur sollicitudin elit eu leo finibus, non volutpat quam dictum. Nam pharetra viverra odio, sit
      amet dapibus leo faucibus ut.</p> <p>hac habitasse platea dictumst. Donec at ex velit. Interdum et malesuada fames ac
      ante ipsum primis in faucibus.</p> </div>
  </div>

</template>

<script>


export default {
  name: 'IntroductionSection'
}

</script>

<style scooped>
.introduction {
  background: url("~@/assets/images/background1.jpg");
  margin: 0 !important;
}

.description {
  background-image: linear-gradient(to bottom right, #412cd7,#432ddc);
  color: white;
  padding: 100px !important;
  font-size: 1.1em;
}
</style>

