<template>

  <nav class="navbar navbar-light cariad-color">
  <a class="navbar-brand" href="#">
    <cariad-logo />
  </a>
</nav>

</template>

<script>
import CariadLogo from '@/modules/formPage/components/CariadLogo'


export default {
  name: 'MenuBar',
  components: {
    CariadLogo
  }
}

</script>

<style scooped>
  .cariad-color{
    background-image: linear-gradient(to bottom right, #412cd7,#432ddc);
  }
  .navbar-brand{
    margin-left: 15px;
  }
</style>

