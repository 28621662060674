<template>

  <card-data :cardData="getCardData" /> 

</template>

<script>

  import { mapGetters, mapActions } from 'vuex'
  import CardData from '@/modules/card/components/CardData'

  export default {
    name: 'RaceResult',
    components: {
      CardData
    },
    computed: {
      ...mapGetters(['getCardData'])
    },
    methods: {
      ...mapActions(['fetchCardData'])
    },
    created() {
      this.fetchCardData(this.$route.params.cardId)
    }
  }

</script>

<style scoped>

</style>
