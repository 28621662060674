<template>

  <div class="contact-section">
    <div class="row">
      <H2 class="section-title">Contact</H2>
    </div>
    <form>
      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
          placeholder="Enter email">
        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Message</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>


</template>

<script>

export default {
  name: 'ContactForm'
}

</script>

<style scooped>
.contact-section {
  background-color: #cdcdd2;
  padding: 30px;
}
textarea {
   resize: none;
}
</style>

