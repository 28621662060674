<template>
  <div class="container">
    <main-menu />
    <div class="content" data-bs-spy="scroll" data-bs-target="#navbar-kuma" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true"  tabindex="0">
      <section-one />
      <section-two />
      <section-three />
    </div>
  </div>
</template>

<script>

import MainMenu from '@/modules/landingPage/components/MainMenu'
import SectionOne from '@/modules/landingPage/components/SectionOne'
import SectionTwo from '@/modules/landingPage/components/SectionTwo'
import SectionThree from '@/modules/landingPage/components/SectionThree'

export default {
  name: 'KumaLanding',
  components: {
    MainMenu,
    SectionOne,
    SectionTwo,
    SectionThree
  },
  methods: {
  }
}

</script>

<style>
@font-face {
  font-family: kidPrint;
  src: url("@/assets/fonts/KidprintMTProBold.OTF");
}

div {
  font-family: kidPrint;
  font-size: 1.1em;
}

h1 {
  text-align: center;
  margin: 40px;
  font-size: 2.5em !important;
  padding-bottom: 15px!important;
  padding-top: 25px!important;
  cursor:default;
}

h2 {
  font-size: 1.5em !important;
  padding-bottom: 15px!important;
  padding-top: 25px!important;
  cursor:default;
}

.content {
  position: relative;
}

.content-image{
  margin-bottom: 5px;
  border-radius: 40px;
}
</style>
