<template>
  <div class="container">
    <menu-bar />
    <introduction-section />
    <satisfaction-form />
    <contact-form />
  </div>
</template>

<script>

import SatisfactionForm from '@/modules/formPage/components/SatisfactionForm'
import ContactForm from '@/modules/formPage/components/ContactForm'
import MenuBar from '@/modules/formPage/components/MenuBar'
import IntroductionSection from '@/modules/formPage/components/IntroductionSection'

export default {
  name: 'CariadLanding',
  components: {
    SatisfactionForm,
    ContactForm,
    MenuBar,
    IntroductionSection
  }
}

</script>

<style scoped>
</style>
