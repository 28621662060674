import { createStore } from 'vuex'
import card from './modules/card/card.store'

const store = createStore({
	modules: {
		card
	}
})

export default store
