<template>

  <div id="why-kuma">
    <h1><span id="menu-1">Why Kuma karuta?</span></h1>
    <div class="row">
      <div class="col-md-6">
        <h2>Why shall we play Kuma karuta?</h2>
        <p>Are you bored, or anxious, are you tired or stressed? Are you
          curious and playful, are you adult or a child? Doesn’t matter!
          Kuma karuta is a card game for everyone!
          By playfully implementing a variety of activities in your daily life,
          we want to stimulate your creative, mental, physical, social,
          creative, emotional, sensory and spiritual wellbeing. Each of those
          seven areas includes seven intensities which help you to experience
          and enjoy powerfull and relaxing moments.
          Be curious, have fun, be you!</p>
      </div>
      <div class="col-md-6"><img src="/assets/images/stack1.jpg" class="img-fluid content-image" /></div>
    </div>
    <div class="row">
      <div class="col-md-6"><img src="/assets/images/card_bench.jpg" class="img-fluid content-image" /></div>

      <div class="col-md-6">
        <h2>Why do we call it Kuma karuta?</h2>
        <p>The name Kuma karuta is derived from the japanese words „Kuma“ meaning „bear“ and „karuta“ meaning something
          like „playful cards“.
          By using playfull cards, we would like you to become like a bear, peaceful and protective like a teddybear, as
          well as strong and powerful like a grizzly.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SectionOne"
}

</script>

<style scoped>
#menu-1::after {
  content: url('@/assets/images/menu/why_static.png');
}

#menu-1:hover::after {
  content: url('@/assets/images/menu/why_ani.gif');
}
</style>

