import axios from 'axios'

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
})

const generalErrorHandler = error => {
  console.error(`[BSI-admin] ApiService ${error}`)
  return Promise.reject({
    errStatus: error.status,
    errData: error.data,
    errRaw: error
  })
}

const collectionResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const singularResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const onRequestStart = (config, app) => {
  app.$Progress.start()
  return config
}

const onResponseFinish = (response, app) => {
  app.$Progress.finish()
  return response
}

export default {
  init(app) {
    api.interceptors.request.use(config => onRequestStart(config, app))
    api.interceptors.response.use(response => onResponseFinish(response, app))
  },
  request(method, resource, params, config) {
    return api
      .request({
        method,
        url: `${resource}`,
        params,
        ...config
      })
      .catch(generalErrorHandler)
  },
  get(resource, slug = '', params) {
    return api
      .request({
        method: 'get',
        url: slug ? `${resource}/${slug}` : `${resource}`,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  query(resource, params) {
    return api
      .request({
        method: 'get',
        url: `${resource}`,
        params
      })
      .then(collectionResponseHandler)
      .catch(generalErrorHandler)
  },
  save(resource, data, params) {
    return api
      .request({
        method: 'post',
        url: `${resource}`,
        data,
        headers: {
            'Accept': 'application/json',
          },
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  download(resource, data, params) {
    return api
      .request({
        method: 'get',
        url: `${resource}`,
        data,
        headers: {
            'Accept': 'application/pdf',
          },
        responseType: 'blob',
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  update(resource, slug = '', data, params) {
    return api
      .request({
        method: 'put',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  patch(resource, slug = '', data, params) {
    return api
      .request({
        method: 'patch',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  delete(resource, slug = '') {
    return api
      .request({
        method: 'delete',
        url: `${resource}/${slug}`
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  upload(resource, slug = '', file, type, params) {
    return api
      .request({
        method: 'post',
        url: `${resource}/${slug}`,
        data: file,
        headers: {
          'Content-Type': type
        },
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  }
}
