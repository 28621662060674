import ApiService from '@/modules/api/services/ApiService'

const race = {
  state: () => ({
    cardData: {}
  }),
  getters: {
    getCardData: state => state.cardData,
  },
  mutations : {
    setCardData (state, data) {
      state.cardData  = {}
      state.cardData = data
    }
  },
  actions:{
    async fetchCardData({ commit }, cardId) {
      try {
            const card = await ApiService.query(`/cards/${cardId}`).then(({ data }) =>
              commit('setCardData', data)
            )
        return Promise.resolve(card)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}

export default race
