<template>

  <div id="who-is">
    <h1><span id="menu-3">Who’s behind
        Kuma karuta?</span></h1>
    <div class="row">
      <div class="col-md-12">
        <img src="/assets/images/members.jpeg" class="img-fluid" />
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  name: "SectionThree"
}

</script>

<style scoped>

#menu-3::after {
  content: url('@/assets/images/menu/who_static.png');
}

#menu-3:hover::after {
  content: url('@/assets/images/menu/who_ani.gif');
}

</style>

